<template>
  <div v-loading="loading">
      <div class="systemCourses-header" style="background-image: url(/images/background/bg5.png);background-size:cover;">
        <div class="systemCourses-title">
          体系课
        </div>
      </div>
       
    <div class="container systemCourses-main">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="card">
            <a href="/course/details?id=1" class="" 
              ><img
                class="card-img-top"
                src="/images/course/PHPSystemCourse2-600.png"
                item="PHP工程师1期"
                alt="PHP工程师1期"
                
            /></a>
            <div class="card-body">
              <p class="card-text">
                <small class="text-muted">预计截止报名：2022-09-01 00:00:00</small><br/>
                <small class="text-muted">预计直播课开班：2022-09-12 20:30:00</small>
              </p>
              <h5 class="card-title">
                <a href="/course/details?id=1" class="" 
                  >PHP工程师1期</a
                >
              </h5>
              <p class="card-text">
                <!-- <small class="text-muted">创意源 发布</small> -->
              </p>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="card">
            <a href="/course/details?id=1" class="" 
              ><img
                class="card-img-top"
                src="/images/course/PHPSystemCourse2-600.png"
                item="JavaScript工程师1期"
                alt="JavaScript工程师1期"
                
            /></a>
            <div class="card-body">
              <p class="card-text">
                <small class="text-muted">预计截止报名：2022-12-01 00:00:00</small><br/>
                <small class="text-muted">预计直播课开班：2022-12-12 20:30:00</small>
              </p>
              <h5 class="card-title">
                <a href="/course/details?id=1" class="" 
                  >JavaScript工程师1期</a
                >
              </h5>
              <p class="card-text">
                <!-- <small class="text-muted">创意源 发布</small> -->
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
      
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'systemCourses',
  props:['user'],
  data() {
    return {
      list: null,
      loading: false,
    }
  },
  created() {
    // this.getNewCourseList()
  },
  methods: {
    async getNewCourseList() {     
      const response = await axios.get('/api/web/course/newList')
      const items = response.data.data.data
      this.list = items
      // console.log(this.list)
      this.loading = false
    },
    async course(id) {
      this.$router.push({path:`/course/${id}`})
    }
  }
}
</script>
